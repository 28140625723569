.fields {
  margin: 1rem 0;
  background-color: var(--white);
  /* border: 1px solid var(--border-color); */
  border-radius: var(--border-radius);
  overflow-y: auto;
}

.block > div:not(div:last-child) {
  margin-right: 0.5rem;
}

.block {
  padding: 1rem;
  display: flex;
  /* flex-wrap: wrap; */
}

.block:not(div:last-child) {
  border-bottom: 1px solid var(--border-color);
}

.block:is(div:first-child) {
  padding-top: 1.5rem;
}
