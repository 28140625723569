.copyRight {
  display: flex;
  justify-content: center;
  margin-top: auto;
  font-size: 0.8rem;
  padding-bottom: 0.5rem;
}

.copyRight a {
  color: black;
}
