.root {
  padding: 1rem 0;
  display: flex; 
  align-items: center;
}

.root:not(div:last-child) {
  border-bottom: 1px solid var(--border-color);
}

.fields > div > div:not(div:last-child),
.root > p {
  margin-right: 0.5rem;
}

.root > p {
  margin-top: 0;
  margin-left: 0;
}

.fields {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fields > div {
  display: flex;
  margin-bottom: 0.5rem;
}

.root {
  flex-direction: column;
  align-items: flex-start;
}
