.label {
    margin-top: 0px !important;

}

.mobile {
    padding-left: 0;
    padding-right: 0;
    padding-top: 10px;
    margin:5px;
    border-radius: 5px !important;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%) !important;
}

.root {
    padding-left: 1rem;
    display: flex;
    padding: 24px 16px 16px 24px;
    margin: 5px;
    border-radius: 5px !important;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%) !important;
    animation: var(--animation);
}



@media (min-width: 1034px) {
    .mobile {
        
        display: none;
    }
}

@media (max-width: 1034px) {
    .root {
        display: none;
    }
}