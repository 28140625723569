.fields {
  margin: 1rem 0;
  background-color: var(--white);
  /* border: 1px solid var(--border-color); */
  border-radius: var(--border-radius);
  overflow-y: auto;
  padding:16px;
}

.bwSectionPadding {
  padding-top: 1rem;
}

.block {
  padding-left: 1rem;
  padding-right: 2rem;
  border-bottom: 1px solid var(--border-color);
}

.block:not(div:last-child) {
  border-bottom: 1px solid var(--border-color);
}

.block>div:not(div:last-child) {
  margin-right: 0.5rem;
}



.column {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 10px 10px;
  padding: 5px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
}

.card {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}


.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}


.row {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin: 0px;
}

.columnMargin {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  margin-left: 10px;
}