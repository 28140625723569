.fields {
    margin: 0rem 0;
    background-color: var(--white);
    /* border: 1px solid var(--border-color); */
    /* justify-content: space-around; */
    height: 90%;
    display: flex;
    flex-flow: column;
    border-radius: var(--border-radius);
    overflow: hidden;

}

.redText{
    color: red;
    margin-left: 10px;
    font-size: 13px;
    display: flex;
    justify-content: center;
}
.body {
    justify-content: space-around;
    display: flex;
    height: 100%;
    flex-direction: column;
}

.column {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 10px 10px;
}
.row {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    /* margin: 10px 10px; */
}

.columnMargin {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    margin-left: 10px;
}

.form {
    margin: .5rem;
    overflow-y: auto;
}

.button {
    margin: .5rem;
    /* padding: 10px; */
    /* overflow-y: auto; */
}

.helpIcon {
    width: 200;
    justify-content: center;
    align-items: center;
}

.chip {
    font-size: 1rem !important;
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    border-radius: var(--border-radius) !important;
    /* border: 1px solid var(--border-color) !important; */
    padding: 1.25rem 0.5rem !important;
    margin-bottom: .5rem !important;
}

.h4 {
    margin: .5rem;
    margin-left: 12px;
    font-size: 18px;
}

.h4Normal {
    font-weight: normal;
    margin: .5rem;
    margin-left: 12px;
    font-size: 18px;
}

.ol {
    margin: .2rem;
    padding-left: 1.6rem;
}