.block {
  padding: 1rem;
  display: flex;
  border-bottom: 1px solid var(--border-color);
}

.block > div:not(div:last-child) {
  margin-right: 0.5rem;
}

.block:not(div:last-child) {
  border-bottom: 1px solid var(--border-color);
}
