.container {
  display: flex;
  flex-direction: row;
  box-shadow: 0 10px 5px 0 rgba(0, 0, 0, 0.01);
  padding: 2px;
  margin: 7px;
  border-radius: 5px;
  justify-content: flex-start;

}

.container:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.09);
}

.value {
  width: 85%;
  height: 100%;
  /* display: flex; */
  padding-left: 10px;
  line-height: 24px;
  word-wrap: break-word;
}


/* .container:not(div:last-child) {
  border-bottom: 1px solid var(--border-color);
} */

.itemFirst {
  padding-left: 5px;
  flex-basis: 40%;
  color: var(--border-color);
}