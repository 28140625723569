.root {
  padding: 1rem 0;
  display: flex;
}

.root:not(div:last-child) {
  border-bottom: 1px solid var(--border-color);
}

.root > div:not(div:last-child) {
  margin-right: 0.5rem;
}

.fields {
  display: flex;
  flex-wrap: wrap;
}

.fields > div {
  margin-right: 0.5rem;
  padding-top: 0.5rem;
}

.column {
  flex-direction: column;
  border-bottom: 1px solid var(--border-color);
}
