.fields {
  margin: 1rem 0;
  background-color: var(--white);
  /* border: 1px solid var(--border-color); */
  border-radius: var(--border-radius);
  overflow-y: auto;
}

.section > div:not(div:last-child) {
  margin-right: 0.5rem;
}

.section {
  padding-left: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.firstblock {
  padding-left: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--border-color);
}

.block {
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
}

.block > div:not(div:last-child) {
  margin-right: 0.5rem;
}

.boxTitle {
  margin-left: 1em;
  font-weight: bold;
}

.expandButton {
  background-color: var(--form-bg);
  border-radius: var(--border-radius);
  margin: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-right: 0.5em;
}

.expandButton > p {
  flex: auto;
}

.root {
  padding: 1rem;
  display: flex;
}

.root:not(div:last-child) {
  border-bottom: 1px solid var(--border-color);
}

.root > div:not(div:last-child) {
  margin-right: 0.5rem;
}
