.fields {
  background-color: var(--white);
  justify-content: space-around;
  height: 100%;
  display: flex;
  flex-flow: column;
  border-radius: var(--border-radius);
  overflow: hidden;
  padding: 1rem;
  animation: var(--animation);

}
