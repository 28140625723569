.root {
  padding: var(--root-padding);
}

.header {
  padding: 0.3rem;
  color: #fff;
  background-color: var(--primary-color);
}

.question {
  background-color: var(--white);
  padding: 0.25rem 0.5rem;
  margin-top: 0.25rem;
  /* border: 1px solid var(--border-color); */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question:last-child {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.buttonGroup {
  display: flex;
}
