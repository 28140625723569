.fields {
  margin: 1rem 0;
  /* border: 1px solid var(--border-color); */
  border-radius: var(--border-radius);
  overflow-y: auto;
}

.field {
  background-color: var(--white);
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 0.5rem;
}

.field:first-of-type {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.field:last-of-type {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  border-bottom: none;
}

@media (min-width: 1025px) {
  .field:hover {
    background-color: var(--bg-hover);
  }
}
