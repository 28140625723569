.fields {
  margin: 1rem 0;
  background-color: var(--white);
  /* border: 1px solid var(--border-color); */
  border-radius: var(--border-radius);
  overflow-y: auto;
}
.root {
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
}

.section > div:not(div:last-child) {
  margin-right: 0.5rem;
}

.section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 1rem;
}

.chip {
  margin-right: 0.5rem;
  width: 27.5% !important;
  flex-direction: row;
  justify-content: space-between !important;
  border-radius: var(--border-radius) !important;
  /* border: 1px solid var(--border-color) !important; */
  padding: 1.25rem 0.5rem !important;
  margin-bottom: 1rem !important;
}
