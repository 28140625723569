.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.2rem;
}

.buttonGroup {
  /* flex-basis: 5%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.3rem;
  padding-top: 1rem;
  /* padding-bottom: 0.8rem; */
}

.buttonGroup button {
  font-size: 13px;
  /* width: 144px !important; */
  display: flex;
  /* margin-right: .5rem; */
}

.backButton {
  width: 144px !important;
  color: var(--primary-color) !important;
  background-color: white !important;
}

.backButton:hover {
  width: 144px !important;
  background-color: white !important;
  opacity: 0.9 !important;
}

.continueButton {
  width: 144px !important;
  color: white !important;
  background-color: var(--primary-color) !important;
}

@media only screen and (max-width: 600px) {
  .submitButton {
    width: 40% !important;
  }
}

.continueButton:hover {
  width: 144px !important;
  opacity: 0.9 !important;
  color: white !important;
  background-color: var(--primary-color) !important;
}

continueButton:disabled,
.continueButton[disabled] {
  width: 144px !important;
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
}