.form {
  flex-basis: 85%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

.fields {
  margin: 1rem 0;
  /* border: 1px solid var(--border-color); */
  border-radius: var(--border-radius);
  overflow-y: auto;
}

.submit {
  /* padding: 0.3rem 1rem 15px; */
  display: flex;
  align-items: flex-end;
}

.field {
  background-color: var(--white);
  border-bottom: 1px solid var(--border-color);
}

.field:first-of-type {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.field:last-of-type {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  border-bottom: none;
}

@media (min-width: 1025px) {
  .field:hover {
    background-color: var(--bg-hover);
  }
}

.labelRoot {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expandIconContainer {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.incidentDetails {
  display: flex;
  border-top: 1px solid var(--border-color);
  background-color: var(--form-bg);
}

.detailsItem {
  flex-basis: 50%;
}

.detailsItem:first-of-type {
  border-right: 1px solid var(--border-color);
}

.detailsHeader {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid var(--border-color);
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.03);
}

.detailsItem p {
  padding: 0.25rem 1rem;
}
