@import url(./utils.css);

:root {
  --primary-color: #222438;
  --animation: fadeIn 2s cubic-bezier(0.075, 0.82, 0.165, 1);
  --body-bg: rgb(244, 247, 248);
  --bg-hover: rgba(255, 255, 255, 0.5);
  --border-color: #ababab;
  --white: #fff;
  --border-radius: 10px;
  --root-padding: 0.3rem 0.3rem 0;
  --form-bg: rgba(241, 241, 241);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-bg);
  -webkit-overflow-scrolling: touch;
}

* {
  box-sizing: border-box;
}

div#app {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

div#formWrapper {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
}

div#captcha-container {
  height: 100vh;
  display: flex;
  align-items: center;

}

div#form-container {
  justify-content: center;
  margin-left: auto;
  width: 100vw;
  max-width: 51.5rem;
  height: 100vh;
  border-radius: var(--border-radius);
  /* box-shadow:rgb(0 0 0 / 20%) 0px 4px 8px 0px; */
  position: inherit;
  background-color: rgb(244, 247, 248);
  display: flex;
  flex-direction: column;
  flex-basis: 100%;

}

@media (min-width: 1800px) {
  div#form-container {
    margin-left: 10rem;
  }
}

@media (max-width: 1034px) {
  div#form-container {
    margin-right: auto;
  }
}

div#form-container>main {
  flex-basis: 100%;
  height: 100%;
  /* overflow: auto; */
}

div#form-container>main .page-root {
  /* height: 100%;  */
  display: flex;
  flex-direction: column;
  padding: var(--root-padding);
}

@media (max-width: 550px) {
  div#form-container>main {
    flex-basis: 100%;
  }
}

/* @media (max-width: 100px) {
  div#formWrapper{
    margin-left: 10rem;
  }
} */