.screeningQuestion,
.section {
  padding: 1rem 0;
  display: flex;
}

.screeningQuestion,
.section:not(div:last-child) {
  border-bottom: 1px solid var(--border-color);
}

.section > div:not(div:last-child) {
  margin-right: 0.5rem;
}

.screeningQuestion {
  align-items: center;
}

.screeningQuestion span {
  font-size: 0.9rem;
  margin-right: 0.5rem;
}
