.card {
    width: 15rem !important;
    position: relative;
    height: 20rem !important;
    border-radius: 3px !important;
    margin-right: auto;
    /* box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px !important; */
}

@media (max-width: 1034px) {
    .card {
        display: none;
    }
}