.root {
  flex:1;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
}
.foi{
  flex:1;
  /* padding:0.5rem; */
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  align-items: flex-start;

}


.root:not(div:last-child) {
  border-bottom: 1px solid var(--border-color);
}

.fields > div:not(div:last-child),
.root > p {
  margin-right: 0.5rem;
}

.root > p {
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 0;
}
