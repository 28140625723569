.root {
  padding: 1rem 0rem;
  display: flex;
  border-bottom: 1px solid var(--border-color);
}

.root:not(div:last-child) {
  border-bottom: 1px solid var(--border-color);
}

.root > div:not(div:last-child) {
  margin-right: 0.5rem;
}