.box {
  display: flex;
  flex-flow: row nowrap;
  position: fixed;
  position: relative;
  display: inline-block;
  width: 100%;
  height:75px;
}

.logo {
  width: 100%;
  background-color: #1a1b2b;
  overflow-x: hidden;
}

.logoText {
  height: 66px;
  width: 100%;
  background-color: #1a1b2b;
  overflow-x: hidden;
}

.logoText img {
  margin-top: .8rem;
  margin-left: .5rem;
  top: 0;
  width: auto;
  height: 45px;
  overflow-y: hidden;
}

.logo img {
  margin-left: 12px;
  width: auto;
  height: 66px;
  overflow-y: hidden;
}

.callMessage {
  align-self: center;
  color: #a50815;
  margin-bottom: 0px;
  font-size:17px;
}

.incidentTypeMessage {
  /* width: 10rem; */
  align-self: center;
  margin: 0px;
  display: flex;
  font-weight: 300;

}

.mobileBranding {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  font-size: 0.8rem;
  transition: transform ease 300ms;
  color: var(--border-color);
  justify-content: flex-end;
  padding: 15px 10px 0px 0px;
}

.mobileBranding img {
  height: 65%;
  width: auto;
}

.desktopBranding {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  font-size: 0.8rem;
  transition: transform ease 300ms;
  color: var(--border-color);
  justify-content: flex-end;
  padding: 12px;
}

.desktopBranding img {
  height: 70%;
  width: auto;
}

.desktopBranding:hover {
  transform: scale(1.10);
  color: var(--white);
  filter: brightness(1.75);
}

.bannerDesktop {
  /* height: 100%; */
  position: absolute;
  left: 23%;
  right: 25%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
  top: .0rem;
  font-size: 1rem;

}

.bannerMobile {
  margin-left: .5rem;
  width: 50%;
  position: absolute;
  left: 29%;
  right: 25%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
  top: .0rem;
  font-size: 1rem;
}

.subHeader {
  background-color: var(--white);

}

.title {
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 10%);
  display: flex;
  justify-content: center;
  margin-right: auto;
  align-items: center;
  /* border-bottom: 1px solid var(--border-color); */
  padding: 0.23rem 0;
  padding-right: 30px;

}




.title b {
  margin-right: auto;
  color: var(--primary-color);
}

.menu {
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
}

.menu>span {
  margin-right: 10px;
  /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%); */
  border-radius: 5px;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  border-right: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

.menu>span:last-of-type {

  /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%); */
  border-right: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  border-radius: 5px;
  /* border-right: none; */
}

.menu>span.active {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 30%);
  border-radius: 5px;
  color: black;
  font-weight: 500;
}

.button {
  background: #2C3D78 !important;
  color: white !important;
}

.button:hover {
  background: white !important;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 30%) !important;
  color: #2C3D78 !important;
}