.root {
  padding: 1rem;
  display: flex;
  border-bottom: 1px solid var(--border-color);
}

.root:not(div:last-child) {
  border-bottom: 1px solid var(--border-color);
}

.root>div:not(div:last-child) {
  margin-right: 0.5rem;
}

.body {
  /* display: flex; */
  height: 80%;
  flex-direction: column;
}

.column {
  margin-bottom: 1rem;
  display: flex;
  flex:1;
  flex-direction: column;
}

.name {
  font-size: 16px;
  padding-left: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  white-space:nowrap;
}

.textFieldStyle{
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .10) !important;
  width: 100% !important;
}

.row{
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 80%;
}

.helpIcon{
  justify-content: center;
  align-items: center;
}