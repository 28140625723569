.form {
  margin-top: 1rem;
  overflow-y: auto;
}

.chip {
  font-size: 1rem !important;
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  border-radius: var(--border-radius) !important;
  /* border: 1px solid var(--border-color) !important; */
  padding: 1.25rem 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
