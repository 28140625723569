.fields {
    margin: 0rem 0;
    background-color: var(--white);
    /* border: 1px solid var(--border-color); */
    /* justify-content: space-around; */
    height: 90%;
    display: flex;
    flex-flow: column;
    border-radius: var(--border-radius);
    overflow: hidden;

}

/* Coubourg Styling */
.readTitle {
    letter-spacing: .5px;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    display: flex;
}


.readParagraph {
    margin-bottom: 2px;
    margin-top: 0.2rem;
    letter-spacing: .5px;
    padding-left: 0.05rem;
    width: 100%;
    line-height: 20px;
    font-size: 15px;

}

.body {
    justify-content: space-around;
    display: flex;
    height: 100%;
    flex-direction: column;
}

.column {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 10px 10px;
}

.columnMargin {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    margin-left: 10px;
}

/* REQUEST CSS*/

.requestText {
    
    letter-spacing: .5px;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
    padding-bottom: 0.5rem;
    font-size: 15px;
}

.name{
    padding-left: 0;
}