.container {
    padding-left: 10px;
}

.canvas {
    display: flex;
    margin-left: 10px;
    width: 95%;
    border-radius: 0.28571429rem;
    padding: 0.3rem;
    border: 2px solid rgba(34, 36, 38, 0.23);
}
