.fields {
    /* margin: 1rem 0; */
    background-color: var(--white);
    /* border: 1px solid var(--border-color); */
    border-radius: var(--border-radius);
    overflow-y: auto;
  }
  
  .section>div:not(div:last-child) {
    margin-right: 0.5rem;
  }
  
  .section {
    padding-left: 1rem;
    display: flex;
    flex-wrap: wrap;
  }

  
  .block {
    
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
  }
  
  .block>div:not(div:last-child) {
    margin-right: 0.5rem;
  }
  
  .questionParagraph {
    line-height: 24px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  }
  
  .row {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  
  .helpIcon {
    justify-content: flex-end;
    align-items: center;
  }
  
  .charactersLeft{
    padding-top: 10px;
    
  }
  
  .h4 {
    /* margin: .5rem; */
    margin-top: 5px;
    font-size: 18px;
  }
  
  .h4Normal {
    font-weight: normal;
    /* margin: .5rem; */
    margin-left: 12px;
    font-size: 18px;
  }