.body {
    /* display: flex; */
    height: 80%;
    flex-direction: column;
}

.icon {
    /* flex:1; */
    height: 50px;
    width: 60px;
    justify-content: center;
    align-items: center;
}

.text {
    margin: 0;
    color: grey;
    opacity: 80%;
}

.text2 {
    font-size: 18px;
    font-weight: 500;
    color: green;
}

.column {
    padding: 20px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 5px;


}