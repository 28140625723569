.root {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  /* border: 1px solid var(--border-color); */
  background-color: var(--white);
  animation-delay: 2s;  
  animation: var(--animation);
  margin-bottom: 10px;
}

.root > div:not(div:last-child) {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 1rem;
  padding-right: 2rem;
  color: #fff;
  background-color: var(--primary-color);
  display: flex;
  justify-content: space-between;
}

.root p {
  margin: 0;
  padding: 1rem;
  font-size: 0.9rem;
  opacity: 0.9;
}

.expandIconContainer {
  display: flex;
  align-items: center;
  padding: 0rem;
}
